/******************************************************************************/
/* MISC                                                                       */
/******************************************************************************/

body.ajax-secured {
  [data-ajax-secured] {
    cursor: default;
  }
  [data-ajax-secured="hidden"] {
    display: none;
  }
}

/******************************************************************************/

.tag-editor {
  border: 1px solid #CCCCCC;
  border-radius: .25rem;
  padding: 0.375rem .75rem;
}

.editor-container {
  z-index: 1;
  border: 1px solid $input-border-color;
  border-radius: $input-border-radius;
}

/******************************************************************************/

#uploader-dropzone {
  border: 5px dashed #CCC;
  border-radius: 10px;
  min-height: 250px;
}

/******************************************************************************/

@each $hbm-opacity-value in $hbm-opacity-values {
  .opacity-#{$hbm-opacity-value} {
    opacity: #{$hbm-opacity-value/100};
  }
}

/******************************************************************************/

table.prepare-for-screenshot {
  width: auto;
}

.hide-for-screenshot {
  display: none;
}

.html2canvas-busy {
  .html2canvas-hide {
    display: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

/******************************************************************************/

.environment-dev {
  border-left:10px solid #dc3545;
}

.environment-stage {
  border-left:10px solid #FFC107;
}

.environment-prod {
}
